<template>
  <div>
    <b-row vs-justify="center">
      <b-col cols="12">
        <b-card class="mb-4">
          <b-card-text>
            <h5 class="card-title">Arrows & Direction Icons</h5>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-up"></i> ti-arrow-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-right"></i> ti-arrow-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-left"></i> ti-arrow-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-down"></i> ti-arrow-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrows-vertical"></i> ti-arrows-vertical
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrows-horizontal"></i> ti-arrows-horizontal
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-up"></i> ti-angle-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-right"></i> ti-angle-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-left"></i> ti-angle-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-down"></i> ti-angle-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-double-up"></i> ti-angle-double-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-double-right"></i> ti-angle-double-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-double-left"></i> ti-angle-double-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-angle-double-down"></i> ti-angle-double-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-move"></i> ti-move
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-fullscreen"></i> ti-fullscreen
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-top-right"></i> ti-arrow-top-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-top-left"></i> ti-arrow-top-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-circle-up"></i> ti-arrow-circle-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-circle-right"></i> ti-arrow-circle-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-circle-left"></i> ti-arrow-circle-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrow-circle-down"></i> ti-arrow-circle-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-arrows-corner"></i> ti-arrows-corner
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-split-v"></i> ti-split-v
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-split-v-alt"></i> ti-split-v-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-split-h"></i> ti-split-h
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hand-point-up"></i> ti-hand-point-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hand-point-right"></i> ti-hand-point-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hand-point-left"></i> ti-hand-point-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hand-point-down"></i> ti-hand-point-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-back-right"></i> ti-back-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-back-left"></i> ti-back-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-exchange-vertical"></i> ti-exchange-vertical
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card class="mb-4">
          <b-card-text>
            <h5 class="card-title">Web App Icons</h5>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-wand"></i> ti-wand
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-save"></i> ti-save
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-save-alt"></i> ti-save-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-direction"></i> ti-direction
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-direction-alt"></i> ti-direction-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-user"></i> ti-user
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-link"></i> ti-link
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-unlink"></i> ti-unlink
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-trash"></i> ti-trash
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-target"></i> ti-target
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-tag"></i> ti-tag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-desktop"></i> ti-desktop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-tablet"></i> ti-tablet
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-mobile"></i> ti-mobile
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-email"></i> ti-email
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-star"></i> ti-star
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-spray"></i> ti-spray
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-signal"></i> ti-signal
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shopping-cart"></i> ti-shopping-cart
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shopping-cart-full"></i> ti-shopping-cart-full
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-settings"></i> ti-settings
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-search"></i> ti-search
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-zoom-in"></i> ti-zoom-in
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-zoom-out"></i> ti-zoom-out
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-cut"></i> ti-cut
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-ruler"></i> ti-ruler
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-ruler-alt-2"></i> ti-ruler-alt-2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-ruler-pencil"></i> ti-ruler-pencil
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-ruler-alt"></i> ti-ruler-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bookmark"></i> ti-bookmark
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bookmark-alt"></i> ti-bookmark-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-reload"></i> ti-reload
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-plus"></i> ti-plus
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-minus"></i> ti-minus
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-close"></i> ti-close
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pin"></i> ti-pin
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pencil"></i> ti-pencil
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pencil-alt"></i> ti-pencil-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-paint-roller"></i> ti-paint-roller
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-paint-bucket"></i> ti-paint-bucket
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-na"></i> ti-na
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-medall"></i> ti-medall
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-medall-alt"></i> ti-medall-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-marker"></i> ti-marker
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-marker-alt"></i> ti-marker-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-lock"></i> ti-lock
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-unlock"></i> ti-unlock
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-location-arrow"></i> ti-location-arrow
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout"></i> ti-layout
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layers"></i> ti-layers
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layers-alt"></i> ti-layers-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-key"></i> ti-key
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-image"></i> ti-image
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-heart"></i> ti-heart
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-heart-broken"></i> ti-heart-broken
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hand-stop"></i> ti-hand-stop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hand-open"></i> ti-hand-open
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hand-drag"></i> ti-hand-drag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-flag"></i> ti-flag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-flag-alt"></i> ti-flag-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-flag-alt-2"></i> ti-flag-alt-2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-eye"></i> ti-eye
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-import"></i> ti-import
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-export"></i> ti-export
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-cup"></i> ti-cup
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-crown"></i> ti-crown
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-comments"></i> ti-comments
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-comment"></i> ti-comment
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-comment-alt"></i> ti-comment-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-thought"></i> ti-thought
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-clip"></i> ti-clip
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-check"></i> ti-check
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-check-box"></i> ti-check-box
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-camera"></i> ti-camera
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-announcement"></i> ti-announcement
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-brush"></i> ti-brush
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-brush-alt"></i> ti-brush-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-palette"></i> ti-palette
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-briefcase"></i> ti-briefcase
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bolt"></i> ti-bolt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bolt-alt"></i> ti-bolt-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-blackboard"></i> ti-blackboard
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bag"></i> ti-bag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-world"></i> ti-world
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-wheelchair"></i> ti-wheelchair
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-car"></i> ti-car
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-truck"></i> ti-truck
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-timer"></i> ti-timer
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-ticket"></i> ti-ticket
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-thumb-up"></i> ti-thumb-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-thumb-down"></i> ti-thumb-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-stats-up"></i> ti-stats-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-stats-down"></i> ti-stats-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shine"></i> ti-shine
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shift-right"></i> ti-shift-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shift-left"></i> ti-shift-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shift-right-alt"></i> ti-shift-right-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shift-left-alt"></i> ti-shift-left-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shield"></i> ti-shield
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-notepad"></i> ti-notepad
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-server"></i> ti-server
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pulse"></i> ti-pulse
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-printer"></i> ti-printer
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-power-off"></i> ti-power-off
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-plug"></i> ti-plug
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pie-chart"></i> ti-pie-chart
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-panel"></i> ti-panel
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-package"></i> ti-package
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-music"></i> ti-music
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-music-alt"></i> ti-music-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-mouse"></i> ti-mouse
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-mouse-alt"></i> ti-mouse-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-money"></i> ti-money
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-microphone"></i> ti-microphone
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-menu"></i> ti-menu
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-menu-alt"></i> ti-menu-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-map"></i> ti-map
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-map-alt"></i> ti-map-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-location-pin"></i> ti-location-pin
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-light-bulb"></i> ti-light-bulb
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-info"></i> ti-info
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-infinite"></i> ti-infinite
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-id-badge"></i> ti-id-badge
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-hummer"></i> ti-hummer
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-home"></i> ti-home
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-help"></i> ti-help
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-headphone"></i> ti-headphone
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-harddrives"></i> ti-harddrives
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-harddrive"></i> ti-harddrive
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-gift"></i> ti-gift
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-game"></i> ti-game
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-filter"></i> ti-filter
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-files"></i> ti-files
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-file"></i> ti-file
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-zip"></i> ti-zip
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-folder"></i> ti-folder
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-envelope"></i> ti-envelope
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-dashboard"></i> ti-dashboard
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-cloud"></i> ti-cloud
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-cloud-up"></i> ti-cloud-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-cloud-down"></i> ti-cloud-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-clipboard"></i> ti-clipboard
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-calendar"></i> ti-calendar
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-book"></i> ti-book
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bell"></i> ti-bell
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-basketball"></i> ti-basketball
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bar-chart"></i> ti-bar-chart
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-bar-chart-alt"></i> ti-bar-chart-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-archive"></i> ti-archive
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-anchor"></i> ti-anchor
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-alert"></i> ti-alert
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-alarm-clock"></i> ti-alarm-clock
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-agenda"></i> ti-agenda
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-write"></i> ti-write
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-wallet"></i> ti-wallet
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-video-clapper"></i> ti-video-clapper
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-video-camera"></i> ti-video-camera
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-vector"></i> ti-vector
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-support"></i> ti-support
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-stamp"></i> ti-stamp
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-slice"></i> ti-slice
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-shortcode"></i> ti-shortcode
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-receipt"></i> ti-receipt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pin2"></i> ti-pin2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pin-alt"></i> ti-pin-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pencil-alt2"></i> ti-pencil-alt2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-eraser"></i> ti-eraser
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-more"></i> ti-more
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-more-alt"></i> ti-more-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-microphone-alt"></i> ti-microphone-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-magnet"></i> ti-magnet
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-line-double"></i> ti-line-double
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-line-dotted"></i> ti-line-dotted
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-line-dashed"></i> ti-line-dashed
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-ink-pen"></i> ti-ink-pen
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-info-alt"></i> ti-info-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-help-alt"></i> ti-help-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-headphone-alt"></i> ti-headphone-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-gallery"></i> ti-gallery
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-face-smile"></i> ti-face-smile
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-face-sad"></i> ti-face-sad
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-credit-card"></i> ti-credit-card
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-comments-smiley"></i> ti-comments-smiley
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-time"></i> ti-time
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-share"></i> ti-share
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-share-alt"></i> ti-share-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-rocket"></i> ti-rocket
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-new-window"></i> ti-new-window
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-rss"></i> ti-rss
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-rss-alt"></i> ti-rss-alt
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card class="mb-4">
          <b-card-text>
            <h5 class="card-title">Control Icons</h5>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-stop"></i> ti-control-stop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-shuffle"></i> ti-control-shuffle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-play"></i> ti-control-play
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-pause"></i> ti-control-pause
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-forward"></i> ti-control-forward
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-backward"></i> ti-control-backward
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-volume"></i> ti-volume
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-skip-forward"></i>
                ti-control-skip-forward
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-skip-backward"></i>
                ti-control-skip-backward
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-record"></i> ti-control-record
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-control-eject"></i> ti-control-eject
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card class="mb-4">
          <b-card-text>
            <h5 class="card-title">Text Editor Icons</h5>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-paragraph"></i> ti-paragraph
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-uppercase"></i> ti-uppercase
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-underline"></i> ti-underline
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-text"></i> ti-text
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-Italic"></i> ti-Italic
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-smallcap"></i> ti-smallcap
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-list"></i> ti-list
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-list-ol"></i> ti-list-ol
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-align-right"></i> ti-align-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-align-left"></i> ti-align-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-align-justify"></i> ti-align-justify
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-align-center"></i> ti-align-center
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-quote-right"></i> ti-quote-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-quote-left"></i> ti-quote-left
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card class="mb-4">
          <b-card-text>
            <h5 class="card-title">Layout Icons</h5>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-width-full"></i> ti-layout-width-full
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-width-default"></i>
                ti-layout-width-default
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-width-default-alt"></i>
                ti-layout-width-default-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-tab"></i> ti-layout-tab
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-tab-window"></i> ti-layout-tab-window
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-tab-v"></i> ti-layout-tab-v
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-tab-min"></i> ti-layout-tab-min
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-slider"></i> ti-layout-slider
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-slider-alt"></i> ti-layout-slider-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-sidebar-right"></i>
                ti-layout-sidebar-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-sidebar-none"></i>
                ti-layout-sidebar-none
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-sidebar-left"></i>
                ti-layout-sidebar-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-placeholder"></i> ti-layout-placeholder
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-menu"></i> ti-layout-menu
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-menu-v"></i> ti-layout-menu-v
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-menu-separated"></i>
                ti-layout-menu-separated
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-menu-full"></i> ti-layout-menu-full
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-right"></i> ti-layout-media-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-right-alt"></i>
                ti-layout-media-right-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-overlay"></i>
                ti-layout-media-overlay
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-overlay-alt"></i>
                ti-layout-media-overlay-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-overlay-alt-2"></i>
                ti-layout-media-overlay-alt-2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-left"></i> ti-layout-media-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-left-alt"></i>
                ti-layout-media-left-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-center"></i>
                ti-layout-media-center
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-media-center-alt"></i>
                ti-layout-media-center-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-list-thumb"></i> ti-layout-list-thumb
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-list-thumb-alt"></i>
                ti-layout-list-thumb-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-list-post"></i> ti-layout-list-post
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-list-large-image"></i>
                ti-layout-list-large-image
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-line-solid"></i> ti-layout-line-solid
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-grid4"></i> ti-layout-grid4
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-grid3"></i> ti-layout-grid3
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-grid2"></i> ti-layout-grid2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-grid2-thumb"></i> ti-layout-grid2-thumb
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-cta-right"></i> ti-layout-cta-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-cta-left"></i> ti-layout-cta-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-cta-center"></i> ti-layout-cta-center
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-cta-btn-right"></i>
                ti-layout-cta-btn-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-cta-btn-left"></i>
                ti-layout-cta-btn-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-column4"></i> ti-layout-column4
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-column3"></i> ti-layout-column3
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-column2"></i> ti-layout-column2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-accordion-separated"></i>
                ti-layout-accordion-separated
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-accordion-merged"></i>
                ti-layout-accordion-merged
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-accordion-list"></i>
                ti-layout-accordion-list
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-widgetized"></i> ti-widgetized
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-widget"></i> ti-widget
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-widget-alt"></i> ti-widget-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-view-list"></i> ti-view-list
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-view-list-alt"></i> ti-view-list-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-view-grid"></i> ti-view-grid
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-upload"></i> ti-upload
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-download"></i> ti-download
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-loop"></i> ti-loop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-sidebar-2"></i> ti-layout-sidebar-2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-grid4-alt"></i> ti-layout-grid4-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-grid3-alt"></i> ti-layout-grid3-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-grid2-alt"></i> ti-layout-grid2-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-column4-alt"></i> ti-layout-column4-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-column3-alt"></i> ti-layout-column3-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-layout-column2-alt"></i> ti-layout-column2-alt
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card class="mb-4">
          <b-card-text>
            <h5 class="card-title">Brand Icons</h5>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-flickr"></i> ti-flickr
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-flickr-alt"></i> ti-flickr-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-instagram"></i> ti-instagram
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-google"></i> ti-google
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-github"></i> ti-github
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-facebook"></i> ti-facebook
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-dropbox"></i> ti-dropbox
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-dropbox-alt"></i> ti-dropbox-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-dribbble"></i> ti-dribbble
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-apple"></i> ti-apple
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-android"></i> ti-android
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-yahoo"></i> ti-yahoo
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-trello"></i> ti-trello
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-stack-overflow"></i> ti-stack-overflow
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-soundcloud"></i> ti-soundcloud
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-sharethis"></i> ti-sharethis
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-sharethis-alt"></i> ti-sharethis-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-reddit"></i> ti-reddit
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-microsoft"></i> ti-microsoft
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-microsoft-alt"></i> ti-microsoft-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-linux"></i> ti-linux
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-jsfiddle"></i> ti-jsfiddle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-joomla"></i> ti-joomla
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-html5"></i> ti-html5
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-css3"></i> ti-css3
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-drupal"></i> ti-drupal
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-wordpress"></i> ti-wordpress
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-tumblr"></i> ti-tumblr
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-tumblr-alt"></i> ti-tumblr-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-skype"></i> ti-skype
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-youtube"></i> ti-youtube
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-vimeo"></i> ti-vimeo
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-vimeo-alt"></i> ti-vimeo-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-twitter"></i> ti-twitter
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-twitter-alt"></i> ti-twitter-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-linkedin"></i> ti-linkedin
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pinterest"></i> ti-pinterest
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-pinterest-alt"></i> ti-pinterest-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-themify-logo"></i> ti-themify-logo
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-themify-favicon"></i> ti-themify-favicon
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 ti-themify-favicon-alt"></i>
                ti-themify-favicon-alt
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ThemifyIcons",
  data: () => ({
    page: {
      title: "Themify Icons",
    },
    breadcrumbs: [
      {
        text: "Icons",
        disabled: false,
        to: "#",
      },
      {
        text: "Themify Icons",
        disabled: true,
      },
    ],
  }),
};
</script>